window.jQuery = window.$ = require('jquery');
import lottie from 'lottie-web';
import Swiper from 'swiper/dist/js/swiper.min.js';
import {TweenMax, TimelineMax} from 'gsap';
import ScrollMagic from 'scrollmagic';
require('scrollmagic/scrollmagic/minified/plugins/animation.gsap.min.js');
require('scrollmagic/scrollmagic/minified/plugins/debug.addIndicators.min.js');
var throttle = require('lodash.throttle');

var names = require('./names.json');
var anim1json = require('./anim1.json');
var anim2json = require('./anim2.json');
var anim3json = require('./anim3.json');

// init main controller
var controller = new ScrollMagic.Controller({ globalSceneOptions: {} });

$(function navigation(){
  // sticky progress indicator
  new ScrollMagic.Scene({
    triggerElement: '#nav',
    triggerHook: 0,
    offset: 0,
    // duration: 0,
  })
  .setPin('#nav', {pushFollowers: false})
  // .addIndicators('sticky')
  .addTo(controller);
});

// build parallax
$(function parallax(){
  $('.parallax').each(
    function( i ) {
      let outer = this;
      let $inner = $(this).find('.bg-map, .bg-map-white, [class^="bg-image-"]');
      let $dots = $(this).find('.bg-dots, .bg-dots-dark');
      
      new ScrollMagic.Scene({
        triggerElement: outer,
        triggerHook: "onCenter", 
        duration: "90%",
        offset: -50,
      })
      .setTween( $inner, {bottom: "0%", ease: Linear.easeNone} )
      // .addIndicators({name: `inner ${i}`})
      .addTo(controller);

      new ScrollMagic.Scene({
        triggerElement: outer,
        triggerHook: "onCenter", 
        duration: "90%",
        offset: -50,
      })
      .setTween( $dots, {bottom: "0%", ease: Linear.easeNone} )
      // .addIndicators({name: `inner ${i}`})
      .addTo(controller);
    }
  );
});

$(function h1slides(){
  $('.h1-wrap-wrap').each(
    function( i ) {
      let wrap = this;
      let $texties = $(this).find('h1');
  
      let tweenie = TweenMax.staggerFromTo(
        $texties, 0.8325, {
        y: "+=100%",
      }, {
        y: "-=100%",
        ease: Linear.easeNone,
      }, 0.02);
      
  
      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0.9,
        duration: 0,
      })
      // .setTween( $texties, { })
      .setTween(tweenie)
      // .addIndicators({name: `haych ${i}`})
      .addTo(controller);
    }
  );
});

$(function sectionIcons(){
  $('.icon-section').each(
    function( i ) {
      let $this = $('.icon-section')[i];
      let iconTween = TweenMax.fromTo(
        $this, 0.8325, 
        { 
        scale: 0.01,
        opacity: 0,
        }, {
        scale: 1,
        opacity: 1,
        ease: Elastic.easeOut,
      });
      
  
      new ScrollMagic.Scene({
        triggerElement: $this,
        triggerHook: 0.9,
        duration: 0,
        offset: 50,
      })
      // .setTween( $texties, { })
      .setTween(iconTween)
      // .addIndicators({name: `haych ${i}`})
      .addTo(controller);
    }
  );
});

$(function quoteAttribs(){
  $('.attr-inner').each(
    function( i ) {
      let $this = $('.attr-inner')[i];

      let attribTween2 = TweenMax.to(
        $this, 0.8325,
        {
          x: '+100%',
          opacity: 0,
          ease: Power2.easeOut,
      });
      new ScrollMagic.Scene({
        triggerElement: $this,
        triggerHook: 0.2,
        duration: 0,
        // offset: '50',
      })
      .setTween(attribTween2)
      // .addIndicators({name: `attrib2 ${i}`})
      .addTo(controller);
      
      let attribTween = TweenMax.from(
        $this, 0.8325,
        {
          x: '+100%',
          opacity: 0,
          ease: Power3.easeOut,
      });
      new ScrollMagic.Scene({
        triggerElement: $this,
        triggerHook: 0.8,
        duration: 0,
      })
      .setTween(attribTween)
      // .addIndicators({name: `attrib ${i}`})
      .addTo(controller);
    }
  );
});

$(function verify(){
  let heightRestrictions = $('#key-restrictions').outerHeight();
  let heightVerification = $('#essential-verification').outerHeight();
  let heightTotal = heightRestrictions + heightVerification;

  // sticky verify quote
  new ScrollMagic.Scene({
    triggerElement: '#verify-stick',
    triggerHook: 0,
    offset: 0,
    duration: heightTotal,
  })
  .setPin('#verify-stick', {pushFollowers: false})
  .addTo(controller);

  // fade in verifies
  var verifyTL = new TimelineMax();
  verifyTL.to('.verify-1', 1, {opacity: 1})
          .to('.verify-2', 1, {opacity: 1})
          .to('.verify-3', 1, {opacity: 1});

  new ScrollMagic.Scene({
    triggerElement: '#verify-stick',
    triggerHook: 0,
    offset: 0,
    duration: heightTotal,
  })
  .setTween(verifyTL)
  .addTo(controller);
});

$(function centrifugesAnim() {
  let $centrifuges = $('.centrifuges img');
  TweenMax.set($centrifuges, {transformOrigin: 'bottom middle'});
  let centrifugesTween = TweenMax.staggerFrom(
    $centrifuges, 0.5, {
      opacity: 0,
      scale: 0.01,
      ease: Power2.easeIn,
    }, 0.05 );

    new ScrollMagic.Scene({
      triggerElement: '.centrifuges',
      triggerHook: 0.8,
      duration: 0,
    })
    // .setTween( $texties, { })
    .setTween(centrifugesTween)
    // .addIndicators({name: `haych ${i}`})
    .addTo(controller);
});


$(function flags() {
  let $flags = $('.flag');
  TweenMax.set($flags, {transformOrigin: 'bottom left'});
  let flagsTween = TweenMax.staggerFrom(
    $flags, 
    0.5, {
    opacity: 0,
    rotation: 90,
    ease: Power2.easeIn,
  }, 0.05 );


  new ScrollMagic.Scene({
    triggerElement: '#flag-trigger',
    triggerHook: 0.8,
    duration: 0,
  })
  // .setTween( $texties, { })
  .setTween(flagsTween)
  // .addIndicators({name: `haych ${i}`})
  .addTo(controller);
});


// scroll-zoom/fade for restrictions in
// Key restrictions are permanent and never sunset
$(function restrictions() {
  $('.restrictions').each(
    function( i ) {
      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: "onCenter",
        duration: $(this).outerHeight(),
        offset: -16,
      })
      // .setClassToggle(this, 'focus')
      .on('enter', () => {
        $('.restrictions').removeClass('focus');
        $(this).addClass('focus');
      })
      // .addIndicators({name: `restriction ${i}`})
      .addTo(controller);
    }
  );
})

// scroll-zoom/fade for events in
// Essential verification and monitoring never ends
$(function events() {
  $('.event').each(
    function( i ) {
      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: "onCenter",
        duration: $(this).outerHeight(),
        offset: -22,
      })
      // .setClassToggle(this, 'focus')
      .on('enter', () => {
        $('.event').removeClass('focus');
        $(this).addClass('focus');
      })
      // .addIndicators({name: `event ${i}`})
      .addTo(controller);
    }
  );
});

$(window).on('scroll', throttle(scrollProgress, 1000));
function scrollProgress() {
  $(window).scroll(function() {
    var scrollPos = controller.scrollPos();
    var docheight = $('.container').height();
    var scrollPct = Math.round((scrollPos / docheight * 5), 2);

    // console.log(scrollPct);
    $('#nav .nav').removeClass('nav-active');
    $('#nav .nav').eq(scrollPct).addClass('nav-active');
  });
}

/* 
    BODY MOVIN / LOTTIE
*/

const anim1 = document.getElementById('anim1');
var anim1movin = lottie.loadAnimation({
  container: anim1, // the dom element that will contain the animation
  renderer: 'svg',
  autoplay: false,
  animationData: anim1json,
  // path: './anim1.json',
});

const anim2 = document.getElementById('anim2');
var anim2movin = lottie.loadAnimation({
  container: anim2, // the dom element that will contain the animation
  renderer: 'svg',
  autoplay: false,
  animationData: anim2json,
  // path: './anim2.json',
});

const anim3 = document.getElementById('anim3');
var anim3movin = lottie.loadAnimation({
  container: anim3, // the dom element that will contain the animation
  renderer: 'svg',
  autoplay: false,
  animationData: anim3json,
  // path: './anim3.json',
});


new ScrollMagic.Scene({
  triggerElement: '#anim1',
  triggerHook: 0.75
})
  .on('enter', () => {
    anim1movin.play();
  })
  .addTo(controller);

new ScrollMagic.Scene({
  triggerElement: '#anim2',
  triggerHook: 0.75
})
  .on('enter', () => {
    anim2movin.play();
  })
  .addTo(controller);

new ScrollMagic.Scene({
  triggerElement: '#anim3',
  triggerHook: 0.75
})
  .on('enter', () => {
    anim3movin.play();
  })
  .addTo(controller);

/* 
    CAROUSELS
*/

var quoteCarousel = new Swiper ('#quote-carousel', {
  loop: true,
  autoplay: {
    delay: 5000,
  },
  pagination: {
    el: '.swiper-pagination',
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});
quoteCarousel.autoplay.stop();

function quoteAuto(){
  quoteCarousel.autoplay.start();
}

let nameWrap = $('#name-wrapper');
let nameLength = names.names.length;
$.each( names.names, function(index, value){
  let nameSplit = value.split(',');
  let personName = nameSplit.shift();
  let personTitle = nameSplit.join().trim();
  
  nameWrap.append(function(){
    return `
    <div class="swiper-slide">
      <div class="name">
        <h5><b>${personName}</b></h5>
        <p>${personTitle}</p>
        <p class="mb-0">${index+1}/${nameLength}</p>
      </div>
    </div>`
  });
});

var nameCarousel = new Swiper ('#name-carousel', {
  loop: true,
  autoplay: { delay: 3000, },
});
nameCarousel.autoplay.stop();

function nameAuto(){
  nameCarousel.autoplay.start();
}


new ScrollMagic.Scene({
  triggerElement: '#quote-carousel',
  triggerHook: 0.8,
})
.on('start', () => {quoteAuto()})
.addTo(controller);

new ScrollMagic.Scene({
  triggerElement: '#name-carousel',
  triggerHook: 0.8,
})
.on('start', () => {nameAuto()})
.addTo(controller);